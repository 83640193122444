import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { EnvSettings } from '../models';

let rum: AwsRum | undefined;

export function initCloudwatchRum(settings: EnvSettings, version: string) {
    if (
        !settings.CLOUDWATCH_RUM.APPLICATION_ID ||
        !settings.CLOUDWATCH_RUM.GUEST_ROLE ||
        !settings.CLOUDWATCH_RUM.IDENTITY_POOL
    ) {
        return undefined;
    }

    if (rum) {
        return rum;
    }

    const cloudwatchSettings = settings.CLOUDWATCH_RUM;
    try {
        const config: AwsRumConfig = {
            guestRoleArn: cloudwatchSettings.GUEST_ROLE,
            identityPoolId: cloudwatchSettings.IDENTITY_POOL,
            sessionSampleRate: 1,
            endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
            disableAutoPageView: true,
            telemetries: [
                'errors',
                'performance',
                ['http', { addXRayTraceIdHeader: true }],
            ],
            allowCookies: true,
            enableXRay: true,
            sessionEventLimit: 0,
        };
        rum = new AwsRum(
            cloudwatchSettings.APPLICATION_ID,
            version,
            'eu-west-1',
            config,
        );
    } catch (error) {
        console.error('initialization failed on AWS RUM', error);
    }
}

export function logCustomEvent(eventType: string, eventData: object) {
    rum?.recordEvent(eventType, eventData);
}

export function logError(error: Error) {
    if (error.message) {
        rum?.recordError(error);
    }
}

export function logPageView(path: string, tags: string[]) {
    rum?.recordPageView({
        pageId: path,
        pageTags: tags,
    });
}

export function getSessionId(): string {
    return rum?.['eventCache'].getSession().sessionId;
}

export function setSessionAttributes(gebruikersId: string, schoolId: string) {
    rum?.addSessionAttributes({
        gebruikersId: gebruikersId,
        schoolId: schoolId,
        width: window.visualViewport?.width ?? 0,
        height: window.visualViewport?.height ?? 0,
        scale: window.visualViewport?.scale ?? 0,
    });
}
