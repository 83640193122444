import { initCloudwatchRum } from './services/cloudwatch-rum';
import { getEnvSettings } from './services/settings-service';

(async () => {
    window.settings = await getEnvSettings();
    initCloudwatchRum(window.settings, VERSION);
    import('./bootstrap');
})();

export {};
