import axios from 'axios';
import { EnvSettings } from 'models/env-settings';

export const getEnvSettings = async () => {
    const environmentFileSuffix = window.location.hostname.endsWith('.be')
        ? '-be'
        : '';
    const rawResponse = await axios.get(
        window.location.origin +
            `/settings/environment${environmentFileSuffix}.json`,
    );
    const response = await rawResponse.data;
    response.ENABLE_ERROR_PANEL = response.ENABLE_ERROR_PANEL === 'True';
    response.EXERCISE_PLAYER_ROOT =
        response.EXERCISE_PLAYER_ROOT + `/remoteEntry.js`;
    return response as EnvSettings;
};
